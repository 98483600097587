@font-face {
    font-family: 'CircularXX';
    src: local('CircularXX') url(../fonts/CircularXX-Book.otf) format("opentype");
  }
  
@font-face {
  font-family: 'CircularXX';
  font-weight: bold;
  src: local('CircularXX') url(../fonts/CircularXX-Bold.otf) format("opentype");
}

:root {
    --font-primary: 'CircularXX', sans-serif;
    --font-secondary: 'CircularXX', sans-serif;
    --text-base-size: 16px;
}

body {
    font-size: var(--text-base-size);
    font-family: var(--font-primary);
}

b, strong, button {
    font-weight: bold;
}

@media screen and (max-width: 799px) {
    h1 {
        font-size: 2rem;
        margin: 0;
    }
    
    h2 {
        font-size: 1.625rem;
        margin: 0;
    }
    
    h3, label {
        font-size: 1.375rem;
    }
    
    h4 {
        font-size: 3rem;
    }
    
    p, button, input, input:-webkit-autofill, input:-webkit-autofill::first-line {
        font-size: 1rem;
    }    
}

@media screen and (min-width: 800px) {
    h1 {
        font-size: 3rem;
        margin: 0;
    }
    
    h2 {
        font-size: 2.15rem;
        margin: 0;
    }
    
    h3, label {
        font-size: 1.6rem;
    }
    
    h4 {
        font-size: 6.2rem;
    }
    
    p, button, input, input:-webkit-autofill, input:-webkit-autofill::first-line {
        font-size: 1.25rem;
    }
}


