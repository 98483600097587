@use '../sassStyles/global';
@import '../sassStyles/variables';

$influent: $brown;
$biofilter: $green;
$effluent: $lightblue;
$well: $darkblue;
$extraction: $darkblue;

//to do: move most of these to a graphicOverview.scss file

//size of pump outer and inner triangle
$a: 7rem;
$pumpBorder: calc(#{$a}*.02);
//$b: calc(#{$a} - #{$pumpBorder*2});

h1 {
    padding-bottom: 1vw;
}

.dashboard {
    flex: 1;
    padding: 1rem 0;
    background-color: $white;
}

@media screen and (max-width: 799px) {
    .dashboard > * {
        padding: 0 1rem;
    }
}

@media screen and (min-width: 800px) {
    .dashboard > * {
        padding: 1.2vw 4vw;  
    }
}

.dashboardList {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.graphicOverviewSection, .historicOverview {
    max-width: $maxWidth;
    margin: 0 auto;
}

.graphicOverview {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0.5fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas: 
    "inf infToBio biofilter bioToEff eff wellToUse"
    ". . . . effToWell wellToUse"
    ". . . . infiltration extraction";
    column-gap: 0;
    row-gap: 0;
    justify-items: center;
    align-items: center;
}

.influent {
    background-color: $influent;
    grid-area: inf;
}

.influentbiofilter {
    grid-area: infToBio
}

.biofilter {
    background-color: $biofilter;
    grid-area: biofilter;  
}

.biofiltereffluent {
    grid-area: bioToEff;
}

.effluent {
    background-color: $effluent;
    grid-area: eff;
}

.effluentinfiltration {
    grid-area: effToWell;
}

.infiltration {
    grid-area: infiltration;
}

.extraction {
    grid-area: extraction;
    background-color: $well
}

.well {
    background-color: $well;
}

.extractionuse {
    grid-area: wellToUse;
}

.container {
    width: 100%;
    height: min-content;
}

.content {
    background-color: hsla(0, 100%, 100%, .8);
    padding: 1rem;
    margin: 0.5rem;
    border: 1px solid white;
}

.content * {
    margin: 0;
}

.connection {
    width: 100%;
    height: 100%;
    display: grid;
    justify-items: center;
    align-items: center;

    & > div {
        grid-column: 1;
        grid-row: 1;        
    }
}

.pump, .verPump {
    position: relative;
    display: grid;
    justify-items: center;
    align-items: center;
    
    h3{
        grid-column: 1;
        grid-row: 1;
        z-index: 2;
    }
}

.pump {
    background-color: transparent;

    //because triangle is sideways
    width: calc(#{$a} * 0.866);
    height: $a;

    background-size: 100% 50%;
    background-repeat: no-repeat;
    background-position: top, bottom;

    &.influent {
        background-image:
        linear-gradient(to bottom left, transparent 50%, $influent 0),
        linear-gradient(to bottom right, $influent 50%, transparent 0);
    }
}

.pump::before {
    content: "";
    grid-column: 1;
    grid-row: 1; 

    background-color: transparent;

    z-index: 1;

    //because triangle is sideways
    width: calc(#{$a} * 0.866);
    height: $a;

    transform: scale(0.90);

    background-size: 100% 50%;
    background-repeat: no-repeat;
    background-position: top, bottom;

    background-image:
    linear-gradient(to bottom left, transparent 50%, white 0),
    linear-gradient(to bottom right, white 50%, transparent 0);
    
}

.verPump {
    background-color: transparent;

    width: $a;
    height: calc(#{$a} * 0.866);

    background-size: 50% 100%;
    background-repeat: no-repeat;
    background-position: left, right;

    &.effluent {
        background-image:
        linear-gradient(to bottom right, transparent 50%, $effluent 0),
        linear-gradient(to top right, $effluent 50%, transparent 0);        
    }

    &.extraction {
        background-image: 
        linear-gradient(to bottom right, transparent 50%, $extraction 0),
        linear-gradient(to top right, $extraction 50%, transparent 0);        
    }
}

.verPump::before {
    content: "";
    grid-column: 1;
    grid-row: 1; 

    background-color: transparent;

    z-index: 1;

    //because triangle is sideways
    width: $a;
    height: calc(#{$a} * 0.866);

    transform: scale(0.90);

    background-size: 50% 100%;
    background-repeat: no-repeat;
    background-position: left, right;

    background-image:
    linear-gradient(to bottom right, transparent 50%, white 0),
    linear-gradient(to top right, white 50%, transparent 0);
    
}

.pipe {
    height: 2rem;
    width: 100%;
    //background-color: white;
}

.verPipe {
    height: 100%;
    width: 2rem;
    margin: 0 auto;
}

.dashboardIframe {
    width: 100%;
    height: min-content;
}