@use '../sassStyles/global';
@import '../sassStyles/variables';

header {
    display: grid;
    align-items: center;
    column-gap: 1rem;
    row-gap: 0.5em;
}

.linkButton {
    grid-column: 2;
    justify-self: start;
}

.logoutButton {
    justify-self: end;
    grid-column: -1;
    white-space: nowrap;
}

@media screen and (max-width: 319px) {
    header {
        grid-template-columns: minmax(auto, 60%);
        padding: 8px;
        column-gap: 0;
    
        img {
            width: clamp(8rem,240px,50vw);
            height: auto;
        }
    }

    .headerLogo {
        display: none;
    }

    .linkButton {
        grid-row: 2;
        grid-column: 1/3;
        padding: 0 0 0 0.5em;
    }
}

@media screen and (max-width: 799px) and (min-width: 320px) {
    header {
        grid-template-columns: minmax(auto, 60%);
        padding: 6vw;
    
        img {
            width: clamp(8rem,240px,50vw);
            height: auto;
        }
    }

    .headerLogo {
        display: none;
    }

    .linkButton {
        grid-row: 2;
        grid-column: 1/-1;
        padding: 0 0 0 0.5em;
    }
}

@media screen and (min-width: 800px) {
    header {
        grid-template-columns: max-content auto max-content;
        padding: 1.2vw 4vw 1.2vw 4vw;
    
        img {
            width: auto;
            max-width: 100%;
            max-height: 65px;
        }
    
        button {
            justify-self: end;
        }
    
    }

    .headerLogoMobile {
        display: none;
    }
}



