// Colours
$black: black;
$white: white;
$brown: #B96945;
$green: #609367;
$sand: #F5EDDF;
$lightblue: #56A8F2;
$darkblue: #2158D8;
$errorred: #E1342E;
$grey: #E1E1DC;

$maxWidth: 75vw;

$breakpoints: (
    small : 320px,
    medium: 800px,
    large: 1024px
);
    


