@import "typography";
@import "variables";


button {
  padding: 1em 1.5em;
  background-color: white;
  border-width: 4px;
  border-style: solid;
  border-color: black;

  &:hover {
    background-color: $darkblue;
    color: $white;
    transition: 0.2s;
  }
}

.linkButton {
  border-style: none;
  background: none;

  &:hover {
    background: none;
    color: $darkblue;
    text-decoration: underline;
  }
}


body {
  margin: 0;
  background-color: $sand;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}