@use '../sassStyles/global';
@import '../sassStyles/_variables';
@import '../sassStyles/_typography';

.errorMessage {
    color: $errorred;
    margin: 0;
}

input[type=text], input[type=password] {
    border: 0px;
}

.inputItem {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 100%;
    label {
        font-weight: bold;
    }
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    box-shadow: 0 0 0px 1000px white inset;
}

.inputField {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    background-color: $white;
    

    input, i {
        grid-row: 1;
    }

    input {
        grid-column: 1/3;
        height: 2.15em;
        padding-left: 0.45em;
    }

    i {
        grid-column: 2;
        z-index: 1;
        line-height: 0;
        padding-right: 0.45em;
        color: $grey;

        &:hover {
            color: $black;
        }
    }
}

@media screen and (max-width: 799px) {
    .loginForm {
        padding: 6vw;
        flex-grow: 1;
        background-color: $sand;
        display: grid;
        justify-items: center;
        align-content: center;
    }

    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.8rem;
    }
    
    .logo {
        height: auto;
        width: 100%;
    }
}

@media screen and (min-width: 800px) {
    .loginForm {
        flex-grow: 1;
        max-width: 300px;
        margin: 0 auto;
        background-color: $sand;
        display: grid;
        justify-items: center;
        align-content: center;
    }

    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.8rem;
    }
    
    .logo {
        height: auto;
        width: 100%;
    }
}

